define("discourse/plugins/discourse-jira/widgets/post-jira-menu", ["exports", "virtual-dom", "discourse/widgets/widget"], function (_exports, _virtualDom, _widget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildManageButtons = buildManageButtons;
  _exports.default = void 0;
  function buildManageButtons(attrs, currentUser) {
    if (!currentUser) {
      return [];
    }
    let contents = [];
    if (currentUser.staff) {
      contents.push({
        icon: "plus",
        className: "popup-menu-button create-issue",
        label: "discourse_jira.menu.create_issue",
        action: "createIssue"
      });
      contents.push({
        icon: "paperclip",
        className: "popup-menu-button attach-issue",
        label: "discourse_jira.menu.attach_issue",
        action: "attachIssue"
      });
    }
    return contents;
  }
  var _default = _exports.default = (0, _widget.createWidget)("post-jira-menu", {
    tagName: "div.post-jira-menu.popup-menu",
    html() {
      const contents = [];
      buildManageButtons(this.attrs, this.currentUser).forEach(b => {
        b.secondaryAction = "closeJiraMenu";
        contents.push(this.attach("post-jira-menu-button", b));
      });
      return (0, _virtualDom.h)("ul", contents);
    },
    clickOutside() {
      this.sendWidgetAction("closeJiraMenu");
    }
  });
  (0, _widget.createWidget)("post-jira-menu-button", {
    tagName: "li",
    html(attrs) {
      return this.attach("button", {
        className: attrs.className,
        action: attrs.action,
        url: attrs.url,
        icon: attrs.icon,
        label: attrs.label,
        secondaryAction: attrs.secondaryAction
      });
    }
  });
});